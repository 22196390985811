<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="`Transferir Atendimento`"
        :header-content="`Transferir este atendimento para outra equipe.`"
      />
      <form class="row" @submit.prevent="transferir">
        <div class="medium-12 columns">
          <label>
            {{ $t('CONVERSATION_SIDEBAR.TEAM_LABEL') }}
            <select v-model="assignToTeam">
              <option v-for="team in teamsList" :key="team.id" :value="team">
                {{ team.name }}
              </option>
            </select>
          </label>
          <div class="modal-footer">
            <woot-submit-button
              :disabled="isButtonDisabled"
              :button-text="`Transferir`"
            />
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('ATTRIBUTES_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import teamMixin from 'dashboard/mixins/conversation/teamMixin';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  mixins: [alertMixin, teamMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    conversationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      assignToTeam: { id: 0, name: 'None' },
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    isButtonDisabled() {
      return this.assignedTeam?.id === this.assignToTeam.id;
    },
    assignedAgent: {
      get() {
        return this.currentChat.meta.assignee;
      },
      set(agent) {
        const agentId = agent ? agent.id : 0;
        this.$store.dispatch('setCurrentChatAssignee', agent);
        this.$store
          .dispatch('assignAgent', {
            conversationId: this.currentChat.id,
            agentId,
          })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
          });
      },
    },
    assignedTeam: {
      get() {
        return this.currentChat.meta.team;
      },
      set(team) {
        const conversationId = this.currentChat.id;
        const teamId = team ? team.id : 0;
        this.$store.dispatch('setCurrentChatTeam', { team, conversationId });
        this.$store
          .dispatch('assignTeam', { conversationId, teamId })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_TEAM'));
          });
      },
    },
  },
  mounted() {
    this.assignToTeam = this.currentChat.meta.team
      ? this.currentChat.meta.team
      : {};
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onClickAssignTeam(selectedItemTeam) {
      if (this.assignedTeam && this.assignedTeam.id === selectedItemTeam.id) {
        this.onCancel();
      } else {
        try {
          const message = {
            sender_type: 'AgentBot',
            sender_id: 2,
            conversationId: this.currentChat.id,
            message: `Seu atendimento foi transferido para a equipe ${selectedItemTeam.name}`,
            private: false,
          };
          this.$store.dispatch('createPendingMessageAndSend', message);

          this.assignedTeam = selectedItemTeam;
          this.assignedAgent = { id: 0 };
        } catch (error) {
          const errorMessage =
            error?.response?.data?.error ||
            this.$t('CONVERSATION.MESSAGE_ERROR');
          this.showAlert(errorMessage);
        }
        bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
        this.onCancel();
      }
    },
    transferir() {
      this.onClickAssignTeam(this.assignToTeam);
    },
  },
};
</script>
<style lang="scss" scoped>
.key-value {
  padding: 0 var(--space-small) var(--space-small) 0;
  font-family: monospace;
}
.multiselect--wrap {
  margin-bottom: var(--space-normal);
  .error-message {
    color: var(--r-400);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
  }
  .invalid {
    ::v-deep {
      .multiselect__tags {
        border: 1px solid var(--r-400);
      }
    }
  }
}
::v-deep {
  .multiselect {
    margin-bottom: 0;
  }
  .multiselect__content-wrapper {
    display: none;
  }
  .multiselect--active .multiselect__tags {
    border-radius: var(--border-radius-normal);
  }
}
</style>
