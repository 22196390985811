import BotpressLayout from './components/BotpressLayout';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/bots'),
    name: 'botpress_studio',
    roles: ['administrator'],
    component: BotpressLayout,
  },
];
