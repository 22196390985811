<template>
  <div class="row app-wrapper">
    <loading-state
      v-if="iframeLoading"
      :message="$t('AGENT_BOTS.LOADING_EDITOR')"
      class="dashboard-app_loading-container"
    />
    <iframe
      v-show="showFrame"
      id="bp-studio--frame"
      name="bpStudio"
      class="bot-editor"
      src="https://bp.fsonline.com.br"
      @load="onEditorLoad"
    />
  </div>
</template>

<script>
import LoadingState from 'dashboard/components/widgets/LoadingState';
// import axios from 'axios';

export default {
  components: { LoadingState },
  data() {
    return {
      bpUrl: 'https://bp.fsonline.com.br',
      iframeLoading: true,
      showFrame: false,
    };
  },
  methods: {
    async onEditorLoad() {
      if (this.iframeLoading === true) {
        this.showFrame = true;
        this.iframeLoading = false;
        //   const frameElement = document.getElementById(`bp-studio--frame`);
        //   const checkAuth = await axios
        //     .get(`${this.bpUrl}/api/v2/admin/user/profile`)
        //     .catch(() => {});
        //   if (checkAuth?.status !== 200) {
        //     await axios
        //       .post(`${this.bpUrl}/api/v2/admin/auth/login/basic/default`, {
        //         email: '',
        //         password: '',
        //       })
        //       .then(response => {
        //         if (response.status === 200) {
        //           const payload = response.data.payload;
        //           const d = new Date();
        //           const issuedAt = d.getTime();
        //           const expiresAt = d.getTime() + 86400000;
        //           localStorage.setItem(
        //             'bp/token',
        //             JSON.stringify({
        //               token: payload.csrf,
        //               expiresAt: expiresAt,
        //               issuedAt: issuedAt,
        //             })
        //           );
        //         }
        //       });
        //   }
        //   frameElement.src = `${this.bpUrl}/admin/workspace/default/bots`;
        //   this.showFrame = true;
        //   this.iframeLoading = false;
      }
    },
  },
};
</script>

<style scoped>
#bp-studio--frame {
  border: 0;
  width: 100%;
  height: 100%;
}

.bot-editor {
  width: 100%;
  height: 100%;
}
</style>
